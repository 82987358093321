import * as Yup from 'yup';
import { TRIAL_STATUSES_CLOSE } from '../../../../constants';

export const schema = Yup.object()
  .shape({
    endDate: Yup.date()
      .typeError('End Date should be set')
      .required('End Date is required')
      .test('minStartDate', 'End Date can\'t be less than Start Date', function (value) {
        const { startDate } = this.parent;
        if (startDate && value && value < startDate) {
          return this.createError({
            message: 'End Date can\'t be less than Start Date',
            path: 'endDate',
          });
        }
        return true;
      })
      .test('minTrialEndDate', 'End Date can\'t be less than Trial End Date', function (value) {
        const { trialEndDate, trialStatus } = this.parent;
        if (trialStatus !== TRIAL_STATUSES_CLOSE.NOT_SET.name && trialEndDate && value && value < trialEndDate) {
          return this.createError({
            message: 'End Date can\'t be less than Trial End Date',
            path: 'endDate',
          });
        }
        return true;
      }),
    trialStatus: Yup.string()
      .test('wrong', 'Wrong Trial Status', function (value) {
        if (value && value === TRIAL_STATUSES_CLOSE.IN_PROGRESS.name) {
          return this.createError({
            message: 'Wrong Trial Status',
            path: 'trialStatus',
          });
        }
        return true;
      }),
    trialEndDate: Yup.date()
      .nullable(),
    turnover: Yup.string(),
    reasonToLeave: Yup.number()
      .required('Reason to leave is required'),
    nextEmployer: Yup.string()
      .nullable()
  })
