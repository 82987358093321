import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { Form, Formik } from 'formik';
import Select from 'react-select';

import store from '../../../../store/store';
import { dispatchPopupState } from '../../../../store/popup/types';
import { setShouldUpdate as setShouldUpdateStaff } from '../../../../store/staff/actions';
import { setShouldUpdate as setShouldUpdateWR } from '../../../../store/workRecord/actions';
import { loaderActions } from "../../../../store/loader/actions";

import dictionaryStore from '../../../../services/dictionary/DictionaryStore';
import wrHelper from '../../../../services/data/WorkRecordHelper';
import WorkRecordClient from '../../../../services/http/WorkRecordClient';

import { DateField } from '../../../Forms/DateField';
import { TypeaheadSuggestionField } from '../../../Forms/TypeaheadSuggestionField';
import { Status } from '../../Common/Status';

import { WorkRecord } from '../../../../interfaces/WorkRecord';

import { PopupCloser } from '../../../Popup/PopupCloser';

import CloseWorkRecordState, { CloseWorkRecordStateInterface } from './CloseWorkRecordState';
import { schema } from './CloseWorkRecordFormSchema';

import { ATTRIBUTE_NAMES, TRIAL_STATUSES_CLOSE } from '../../../../constants';

import './closer-work-record-form.scss';

const labels = ATTRIBUTE_NAMES['closeWorkRecord'];
const trialStatusList: Array<any> = [];
Object.entries(TRIAL_STATUSES_CLOSE).forEach(([k, v]) => trialStatusList.push({ label: v.name, value: v.name }));

interface CloseWorkRecordFormProps {
  popupName: string
  workRecord: WorkRecord
}

export const CloseWorkRecordForm: React.FC<CloseWorkRecordFormProps> = (props: CloseWorkRecordFormProps) => {
  const { workRecord, popupName } = { ...props };
  const [initialState, setInitialState] = useState<CloseWorkRecordStateInterface | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSavingState, setIsSavingState] = useState<boolean>(false);
  const [turnoverList, setTurnoverList] = useState<Array<any>>([]);
  const [reasonToLeaveList, setReasonToLeaveList] = useState<Array<any>>([]);
  const [nextEmployers, setNextEmployers] = useState([]);
  const [isWrongStatus, setIsWrongStatus] = useState<boolean>(true);
  const [isWrongDiffEndDates, setIsWrongDiffEndDates] = useState<boolean>(true);

  const submission = (values: CloseWorkRecordStateInterface) => {
    setIsSavingState(true);
    store.dispatch(loaderActions.showLoader());
    WorkRecordClient.getCloseWorkRecordInfo(workRecord.staffId)
      .then((data: any) => {
        if (data.hasSubordinatesOrUnits) {
          if (window.confirm(data.validateMessage)) {
            closeWR(values);
          }
        } else {
          closeWR(values);
        }
      })
      .catch(() => {
        toastr.error('', 'Something went wrong and we can\'t check does this person is line/unit manager or not');
      })
      .finally(() => {
        setIsSavingState(false);
        store.dispatch(loaderActions.hideLoader());
      })
  };

  const closeWR = (values: CloseWorkRecordStateInterface) => {
    store.dispatch(loaderActions.showLoader());
    wrHelper.close(workRecord, values)
      .then((data: any) => {
        store.dispatch(setShouldUpdateWR(true));
        store.dispatch(setShouldUpdateStaff(true));
        dispatchPopupState(popupName, false);
      })
      .finally(() => {
        store.dispatch(loaderActions.hideLoader());
      });
  };

  const checkIfCm = () => {
    WorkRecordClient.getIsCm(workRecord.staffId).then((data: any) => {
      if (data.isCm) {
        toastr.warning('', 'The person is a Compensation Manager to some active Work Records, please re-assign them');
      }
    }).catch(() => {
      toastr.error('', 'Something went wrong and we can\'t check is this person Compensation manager or not');
    });
  };

  const disableLayoffButton = () => {
    return isWrongStatus || isWrongDiffEndDates || isSavingState;
  };

  const checkTrialStatus = (trialStatus: string) => {
    setIsWrongStatus(trialStatus === TRIAL_STATUSES_CLOSE.IN_PROGRESS.name);
    if (trialStatus === TRIAL_STATUSES_CLOSE.NOT_SET.name) {
      setIsWrongDiffEndDates(false);
    }
  }

  const validateDatesDiff = (endDate: Date | null, trialEndDate: Date | null, trialStatus: string) => {
    if (trialStatus === TRIAL_STATUSES_CLOSE.NOT_SET.name) {
      setIsWrongDiffEndDates(false);
      return;
    }

    if (null !== endDate && null !== trialEndDate) {
      const endDateVal = endDate.setHours(0,0,0);
      const trialEndDateVal = trialEndDate.setHours(0,0,0);

      const isTrialEndDateValGreater = trialEndDateVal > endDateVal;
      setIsWrongDiffEndDates(isTrialEndDateValGreater);
    }
  }

  useEffect(() => {
    if (initialState == null) {
      setInitialState(CloseWorkRecordState.generateFromWorkRecord(workRecord));
      checkTrialStatus(workRecord.trialStatus);
      validateDatesDiff(workRecord.endDate, workRecord.trialEndDate, workRecord.trialStatus);
    }
    if (!isLoaded) {
      dictionaryStore.getByAlias('turnover').then((data: any) => {
        if (typeof data !== 'undefined') {
          const options = data.map((item: string) => ({ label: item, value: item }));
          setTurnoverList(options);
        }
      });
      dictionaryStore.getByAlias('reason_to_leave').then((data: any) => {
        if (typeof data !== 'undefined') {
          setReasonToLeaveList(data);
        }
      });
      dictionaryStore.getByAlias('next_employer').then((data: any) => {
        if (typeof data !== 'undefined') {
          setNextEmployers(data);
        }
      });
      setIsLoaded(true);
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded])

  if (initialState) {
    return (
      <Formik
        validateOnBlur={ true }
        initialValues={ initialState }
        validationSchema={ schema }
        onSubmit={ values => { submission(values); }}>
        {({ errors, touched, values, handleChange, setFieldValue, setFieldTouched }) => (
          <Form className="edb-form form-close-wr">
            <div className="info">
              Please verify the End Date, trial period and turnover for this work record.
              <br/>
              Once you press Close, Work Record will become inactive:
            </div>
            <div className="form-close-wr-header">
              <div className="clearfix">
                <div className="main-attribute float-left">
                  { workRecord.position.value }
                </div>
                <div className="status float-right">
                  <Status value={ workRecord.status } />
                </div>
              </div>
              <div className="secondary-attribute">
                <span>{ workRecord.employment.value }</span>
                <span>{`${workRecord.location.value}, ${workRecord.location.country}`}</span>
              </div>
            </div>
            <div className="form-close-wr-body">
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="startDate">{ labels['startDate'] }</label>
                    <DateField
                      autoComplete='off'
                      id="startDate"
                      name="startDate"
                      disabled={ true }
                      className="form-control"
                      onChange={ () => {} }
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="endDate">{ labels['endDate'] }</label>
                    <DateField
                      autoComplete='off'
                      id="endDate"
                      name="endDate"
                      className={ `form-control ${errors.endDate && touched.endDate ? 'invalid-field' : ''}` }
                      onChange={(date: Date) => {
                        validateDatesDiff(date, values.trialEndDate, values.trialStatus);
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="turnover">{ labels['turnover'] }</label>
                    <Select
                      id="turnover"
                      name="turnover"
                      value={ turnoverList.filter((item) => item.value === values.turnover) }
                      options={ turnoverList }
                      isSearchable={ turnoverList.length > 10 }
                      isClearable={ false }
                      maxMenuHeight={ 200 }
                      menuPlacement={ 'bottom' }
                      onChange={ (selected) => setFieldValue('turnover', selected ? selected.value : '') }
                      classNamePrefix={ 'select-field-custom' }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  {
                    (values.trialStatus !== TRIAL_STATUSES_CLOSE.NOT_SET.name)
                      ? (
                        <div className="form-group">
                          <label htmlFor="trialEndDate">{labels['trialEndDate']}</label>
                          <DateField
                            autoComplete='off'
                            id="trialEndDate"
                            name="trialEndDate"
                            className={`form-control${errors.trialEndDate ? ' invalid-field' : ''}`}
                            onChange={(date: Date) => {
                              validateDatesDiff(values.endDate, date, values.trialStatus);
                            }}/>
                        </div>
                      )
                      : <></>
                  }
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="trialStatus">{ labels['trialStatus'] }</label>
                    <Select
                      id="trialStatus"
                      name="trialStatus"
                      value={ trialStatusList.filter((item) => item.value === values.trialStatus) }
                      options={ trialStatusList }
                      isSearchable={ trialStatusList.length > 10 }
                      isClearable={ false }
                      maxMenuHeight={ 200 }
                      menuPlacement={ 'bottom' }
                      onChange={ (selected) => {
                        setFieldTouched('trialStatus');
                        const value = selected ? selected.value : '';
                        setFieldValue('trialStatus', value);
                        checkTrialStatus(value);
                      } }
                      classNamePrefix={ 'select-field-custom' }
                      className={ errors.trialStatus && touched.trialStatus || !touched.trialStatus && values.trialStatus === TRIAL_STATUSES_CLOSE.IN_PROGRESS.name ? 'invalid-field' : '' }
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="reasonToLeave">{ labels['reasonToLeave'] }</label>
                    <Select
                      id="reasonToLeave"
                      name="reasonToLeave"
                      value={ reasonToLeaveList.filter((item) => item.id === values.reasonToLeave) }
                      options={ reasonToLeaveList }
                      getOptionLabel={ (option) => option.title }
                      getOptionValue={ (option) => option.id }
                      isSearchable={ reasonToLeaveList.length > 10 }
                      isClearable={ false }
                      maxMenuHeight={ 200 }
                      menuPlacement={ 'bottom' }
                      onChange={ (selected) => setFieldValue('reasonToLeave', selected ? selected.id : '') }
                      classNamePrefix={ 'select-field-custom' }
                      className={ errors.reasonToLeave && touched.reasonToLeave ? 'invalid-field' : '' }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="nextEmployer">{ labels['nextEmployer'] }</label>
                    <TypeaheadSuggestionField
                      id="nextEmployer"
                      name="nextEmployer"
                      placeholder="Next Employer"
                      labelKey=""
                      multiple={ false }
                      options={ nextEmployers }
                      className="form-control w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-close-wr-footer">
              <div className="form-close-wr-footer-close-button edb-pull-right">
                <button type="submit" className="edb-btn edb-btn--primary" disabled={ disableLayoffButton() }>
                  Close Record
                </button>
              </div>
              <div className="form-close-wr-footer-cancel-button edb-pull-right">
                <PopupCloser popupName={ popupName }>
                  <button className="edb-btn edb-btn--secondary" type="reset">
                    Cancel
                  </button>
                </PopupCloser>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
  return <></>;
}
